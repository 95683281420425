<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->

            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-xl-3 p-0 col-md-10">
                    <h3 class="mb-0 text-white">{{room.title}}</h3>
                    <h2 class="display-2 text-white">Cenotvorba</h2>
                    </div>
                <div class="col-xl-9" style="padding:0">
                    <h3 class="mb-4 text-white"></h3>
                    <h3 class="display-2 text-white">{{monthDisplay}}</h3>

            </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-3 order-xl-3">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    Vyberte si rozmedzie a určite cenu
                                </div>
                            </div>
                        </div>
                        <template>

   <span class="params" v-if="room.object !== true">
                         <div class="p">
                             <div class="par" v-for="index in parseInt(room.beds)" >
                          <label class="form-control-label">cena pre {{index + ' x dospelý'}}</label>
                        <a-input :placeholder="room.price[index]" type="number" suffix="€" v-model="fieldData.type[index]" class="mb-3" />
                        </div>
                             <span v-if="room.extrabed" >
                         <div class="par" v-for="index in parseInt(room.extrabed)" >
                          <label class="form-control-label">cena navyše {{index + ' x prístelka'}}</label>
                             <a-input :placeholder="room.price['p'+index]"  type="number" suffix="€" v-model="fieldData.type['p'+index]" class="mb-3" />
                        </div>
                             </span>
                         </div>
                     </span>
<span v-else>
   <label class="form-control-label">cena za objekt</label>
    <a-input :placeholder="room.price[0]" type="number" suffix="€" v-model="fieldData.type[0]" class="mb-3" />
</span>

  <a-button class="mt-3" type="primary" @click="handleSubmit">Aktualizovať</a-button>
                        </template>

                    </a-card>

                </div>
                <div class="col-xl-9 calendar-view order-xl-9" v-if="prices">
                    <a-card shadow type="secondary">
                        <div class="par"  v-if="room.object !== true">
                        <a-radio-group v-model="selectedcount" class="people-selector">  <label class="main-description">Vyberte si konfiguráciu izby</label>
                            <a-radio-button :value="''+index" v-for="index in parseInt(room.beds)">
                                {{index}} x dospelý
                            </a-radio-button>
                         <spna v-if="room.extrabed">   <a-radio-button :value="'p'+index" v-for="index in parseInt(room.extrabed)">
                                {{index}} x prístelka
                            </a-radio-button></spna>
                        </a-radio-group>


                        </div>
                    <a-calendar :fullscreen="true" :locale="locale" @panelChange="onPanelChange">

                        <div slot="dateCellRender" slot-scope="value" class="prices">
                            <div  :class="'price ' + [prices[backEndDateFormat(value)] ? '' : 'default']">
                                    <span v-if="prices[backEndDateFormat(value)]">
                                        <span v-if="prices[backEndDateFormat(value)][selectedcount]"> {{prices[backEndDateFormat(value)][selectedcount]}} €</span>
                                <span class="default" v-else>{{room.price[selectedcount]}} €</span>
                                    </span>
                                    <span v-else> {{room.price[selectedcount]}} €</span>
                            </div>
                        </div>
                    </a-calendar>
                </a-card>

            </div>  </div>
        </div>  </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import ADatePicker from "ant-design-vue/es/date-picker";
    import moment from 'moment'
    import 'moment/locale/sk';
    import ARadioGroup from "ant-design-vue/es/radio/Group";
    import ARadioButton from "ant-design-vue/es/radio/RadioButton";

    Vue.use(FormItem);


    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Začiatok", "Koniec"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storoťie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }


    export default {
        name: 'roomprice',
        components: {"a-radio-button": ARadioButton, "a-radio-group": ARadioGroup, ADatePicker, FormItem},
        props: ['id','item'],

        data() {
            return {
                fieldData: {
                    type: {}
                },
                colorsto: JSON.parse(localStorage.colors),
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                locale,
                selectedcount:1,
                monthDisplay:moment().format('MMMM YYYY'),
                prices:{},
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 20, offset: 4 },
                    },
                },
                head: authHeader(),
                send: API_URL,
                room: {
                    title: '',
                    type: '',
                },
            }
        },
        methods: {
            backEndDateFormat(date) {
                return moment(date._d).format('YYYY-MM-DD');
            },
            Month(date) {
                return moment(date._d).format('MMMM YYYY');
            },
            onPanelChange(value) {
              this.monthDisplay = this.Month(value);
            },
            handleSubmit() {

                    dataService.axiosPost("saveprices/"+this.id, this.fieldData).then(results => {
                        this.prices = results.data.data.prices;
                    });

            },
        },
        mounted() {
            if (this.id) {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.room = results.data;
                    this.prices = results.prices;
                    if(this.room.object === true)
                        this.selectedcount = 0;
                });
            }

        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .price
    {font-weight:bold;position:absolute;bottom:10%}
    .price span
    {font-size:25px;}
    .price.default,.price .default
    {color:#e5e5e5;}
    .ant-fullcalendar-fullscreen .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-date
    {height:95px}

    .ant-select-selection--single
    {width:100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;}

    .ant-fullcalendar-last-month-cell,.ant-fullcalendar-next-month-btn-day
    {background-color: #f6f6f6
    }
    .people-selector
    {position:absolute;
    top:20px;}
    .people-selector .main-description
    { display:inline-block;width:100%;}
    .ant-fullcalendar-cell
    {backgrouynd-color:#fff;}
    .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date
    {background-color:unset;color:unset;}
    .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value, .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value
    {color:unset;}
    .ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover, .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover,.ant-fullcalendar-value:hover
    {cursor:default;background-color:unset;}
.calendar-view h2
{position:absolute;font-size:30px;color: #4fabd0; font-weight:200;text-transform:uppercase;padding:10px; padding-top:0}

</style>
